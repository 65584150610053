<template>
  <AdminSettingsProfile />
</template>

<script>
import AdminSettingsProfile from '../components/settings_users/AdminSettingsProfile.vue';

export default {
  components: { AdminSettingsProfile }
};
</script>

<style></style>
