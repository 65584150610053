<template>
  <div>
    <div>
      <b-spinner v-if="loading" type="grow" label="Loading..."></b-spinner>
      <div v-else>
        <b-table hover :items="getAllUsers" :fields="fieldsUser">
          <template #cell(№)="{index}">
            <p>
              {{ index + 1 }}
            </p>
          </template>
          <template #cell(Role)="{item}">
            <p>
              {{ item.DogUser ? 'Владелец' : item.Role.DisplayName }}
            </p>
          </template>
          <template #cell(menu)="{item}">
            <b-dropdown id="dropdown-dropright" :disabled="item.DogUser" dropright variant="danger">
              <template #button-content>
                <b-icon icon="card-list" aria-hidden="true"></b-icon> Меню
              </template>
              <b-dropdown-item href="#" v-b-modal.updateUserModal @click="updateUser(item)"
                >Редактировать</b-dropdown-item
              >
              <b-dropdown-item href="#" @click="delUser(item.Id)">
                Удалить
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <b-modal id="updateUserModal" hide-footer title="Редактирование пользователя">
          <UpdateUser :item="user" />
        </b-modal>
        <CreateUser />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CreateUser from '@/components/settings_users/CreateUser.vue';
import UpdateUser from '@/components/settings_users/UpdateUser.vue';
import { catchFunction, ShowSuccesToUser } from '@/additional_helpers/helpers';

export default {
  data() {
    return {
      loading: false,
      user: null,
      fieldsUser: [
        { key: '№' },
        { key: 'UserName', label: 'логин' },
        { key: 'DisplayName', label: 'имя' },
        { key: 'CustomerName' },
        { key: 'ContractNumber', label: 'договор' },
        { key: 'Role', label: 'права' },
        { key: 'menu', label: 'меню' }
      ],
      messageBoxConfirm: {
        title: 'Вы уверены, что хотите удалить пользователя?',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'ДА',
        cancelTitle: 'НЕТ',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }
    };
  },

  computed: {
    ...mapGetters({ getAllUsers: 'profileSettings/getUsers' })
  },

  methods: {
    ...mapActions({
      readAllProfileAction: 'profileSettings/readAllprofileAccount',
      delUserAction: 'profileSettings/delUser'
    }),

    updateUser(user) {
      this.user = user;
    },

    async delUser(id) {
      this.$bvModal
        .msgBoxConfirm('Вы собираетесь удалить пользователя', this.messageBoxConfirm)
        .then((value) => {
          if (value) {
            this.loading = true;
            this.delUserAction(id)
              .then(() => {
                ShowSuccesToUser('Пользователь удален');
                this.readAllProfileAction()
                  // eslint-disable-next-line no-return-assign
                  .then(() => (this.loading = false));
              })
              .catch((e) => catchFunction(e));
          }
        })
        .catch((e) => catchFunction(e));
    }
  },

  components: {
    CreateUser,
    UpdateUser
  }
};
</script>

<style scoped></style>
