<template>
  <div>
    <b-dropdown
      id="dropdown-form"
      text="Добавить пользователя"
      style="z-index: 1030"
      ref="dropdownForm"
    >
      <b-dropdown-form @submit.stop.prevent="addUser">
        <b-form-group v-for="input in formInputsFields" :key="input.id" :label="input.label">
          <BaseTextInput
            :id="input.id"
            v-model="input.value"
            type="text"
            size="sm"
            :placeholder="input.placeholder"
          />
        </b-form-group>
        <b-form-group label="Права пользователя" label-for="roleUser">
          <b-form-select :options="getUserRolesGetter" id="roleUser" v-model="roleUser">
          </b-form-select>
        </b-form-group>
        <b-spinner v-if="loading" type="grow" label="Loading..."></b-spinner>
        <BaseButton v-else>
          <template #text> Создать </template>
        </BaseButton>
      </b-dropdown-form>
    </b-dropdown>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BaseButton from '@/components/shared/buttons/BaseButton.vue';
import BaseTextInput from '@/components/shared/inputs/BaseTextInput.vue';

export default {
  data() {
    return {
      roleUser: '',
      loading: false,
      formInputsFields: [
        {
          id: 'login',
          label: 'Логин',
          value: '',
          placeholder: 'Укажите логин'
        },
        {
          id: 'password',
          label: 'Пароль',
          value: '',
          placeholder: 'Пароль'
        },
        {
          id: 'userName',
          label: 'Имя',
          value: '',
          placeholder: 'Имя пользователя'
        }
      ]
    };
  },

  components: {
    BaseButton,
    BaseTextInput
  },

  computed: {
    ...mapGetters({ getUserRolesGetter: 'profileSettings/getUserRoles' })
  },

  methods: {
    ...mapActions({
      readAllProfile: 'profileSettings/readAllprofileAccount',
      readRoleUser: 'profileSettings/readRoleUser',
      addUserAction: 'profileSettings/addUser'
    }),

    async addUser() {
      this.loading = true;

      const [login, password, userName] = this.formInputsFields;
      const user = {
        Login: login.value,
        Password: password.value,
        Name: userName.value,
        RoleId: +this.roleUser
      };

      await this.addUserAction(user);
      await this.readAllProfile();
      this.loading = false;
      this.$refs.dropdownForm.hide(true);
    }
  },

  async beforeMount() {
    await this.readRoleUser();
  }
};
</script>

<style scoped></style>
