<template>
  <b-form @submit.stop.prevent="updateUser">
    <b-form-group v-for="input in formInputsFields" :key="input.id" :label="input.label">
      <BaseTextInput
        :id="input.id"
        v-model="input.value"
        type="text"
        size="sm"
        :placeholder="input.placeholder"
      />
    </b-form-group>
    <b-form-group label="Права пользователя" label-for="roleUser">
      <b-form-select :options="getUserRolesGetter" id="roleUser" v-model.number="roleUser">
      </b-form-select>
    </b-form-group>
    <b-spinner v-if="loading" type="grow" label="Loading..."></b-spinner>
    <BaseButton v-else>
      <template #text> Обновить </template>
    </BaseButton>
  </b-form>
</template>

<script>
import BaseTextInput from '@/components/shared/inputs/BaseTextInput.vue';
import { mapActions, mapGetters } from 'vuex';
import BaseButton from '@/components/shared/buttons/BaseButton.vue';

export default {
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      password: '',
      userName: '',
      roleUser: '',
      loading: false,
      formInputsFields: [
        {
          id: 'password',
          label: 'Пароль',
          value: '',
          placeholder: 'Пароль'
        },
        {
          id: 'userName',
          label: 'Имя',
          value: '',
          placeholder: 'Имя пользователя'
        }
      ]
    };
  },

  components: {
    BaseButton,
    BaseTextInput
  },

  computed: {
    ...mapGetters({ getUserRolesGetter: 'profileSettings/getUserRoles' })
  },

  methods: {
    ...mapActions({
      readRoleUser: 'profileSettings/readRoleUser',
      readAllProfiles: 'profileSettings/readAllprofileAccount',
      updateUserByStore: 'profileSettings/updateUser'
    }),

    async updateUser() {
      this.loading = true;

      const [password, userName] = this.formInputsFields;
      const user = {
        Password: password.value,
        Name: userName.value,
        RoleId: this.roleUser,
        id: this.item.Id
      };
      await this.updateUserByStore(user);
      await this.readAllProfiles();
      this.loading = false;
      this.$bvModal.hide('updateUserModal');
    }
  },

  beforeMount() {
    this.readRoleUser();
  },
  mounted() {
    this.formInputsFields[1].value = this.item.DisplayName;
    this.roleUser = this.item.Role.Id;
  }
};
</script>

<style></style>
